import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import {useSpring, animated} from 'react-spring'

import './content.sass'


  export const AboutPageTemplate = ({ title, image, content, contentComponent, mount }) => {
  const PageContent = contentComponent || Content

  const contentFade = useSpring(
    {
      config: { duration: 1000 },
      from : {opacity:0},
      to : {opacity: mount ? 1 : 0},
    }
  );

  return (
      <animated.div className='container contentWrap' style={contentFade}>
        <div className='pageWrapper'>
          <div className="columns">
            <div className="column is-two-thirds">
              <PreviewCompatibleImage imageInfo={image} />
              <br></br>
              <PageContent className="content" content={content} />
            </div>
            <div className="column">
            </div>
          </div>
        </div>

      </animated.div>
  )

}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contentComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark
  const { mount, transitionStatus, entry, exit } = useTransitionState();

  return (
    // <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={frontmatter.image}
        content={post.htmlAst}
        transitionStatus={transitionStatus}
        entry={entry}
        mount={mount}
        exit={exit}
      />
    // </Layout>
  )
}

// AboutPage.propTypes = {
//   data: PropTypes.object.isRequired,
// }

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 658, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
